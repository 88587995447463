<template>
  <div class="bg_login">
    <section class="wrap_bg">
      <div class="inner_login bg">
        <atoms-c-image
          class="img_block img_block_1"
          width="160"
          height="63"
          file-name="login-block-cloud"
          :density="3"
        />
        <atoms-c-image
          class="img_block img_block_2"
          width="221"
          height="113"
          file-name="login-block-cloud-2"
          :density="3"
        />
      </div>
    </section>
    <section class="inner_login content">
      <header class="header_login">
        <atoms-c-image
          file-name="ci-hellomaple-shadow"
          :density="3"
          @click="onClickLogo"
        />
      </header>
      <article class="content_login">
        <slot />
      </article>
    </section>
  </div>
</template>

<script lang="ts" setup>
const router = useRouter()

function onClickLogo() {
  router.push('/')
}
</script>

<style lang="scss">
.bg_login {
  position: relative;

  @include imageSet('@/assets/images/login/bg-login');
  background-position: center calc(100% + 1px);
  background-color: #f9f4f1;

  .wrap_bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  .inner_login {
    margin: 0 auto;
    max-width: 720px;

    &.bg {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 176px;
      height: 512px;
      > .img_block {
        position: absolute;
        &_1 {
          top: 15.8px;
          left: -468px;
        }
        &_2 {
          top: -13px;
          right: -373px;
        }
      }
    }
    &.content {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding-top: 70px;
      padding-bottom: 56px;
      z-index: 1;
      .header_login {
        line-height: 0;
        margin-bottom: 32px;
        text-align: center;
        cursor: pointer;

        @include tablet() {
          margin-bottom: 24px;
          > img {
            width: 160px;
          }
        }
      }
    }
    .content_login {
      box-sizing: border-box;
      //  height: 808px;
      padding: 56px 64px;
      border-radius: 8px;
      background: $white;
      box-shadow: 0px 4px 40px 0px rgba(59, 103, 181, 0.06);
      > h1 {
        color: $black0; // --black-black-0
        font-size: 36px;
        font-weight: 800;
        line-height: 140%; /* 50.4px */
        text-align: center;
      }
      > h3 {
        margin-top: 8px;
        color: $black1;
        text-align: center;

        font-size: 18px;

        font-weight: 400;
        line-height: 150%;
        white-space: pre-wrap;
      }
      @include tablet() {
        padding: 32px 16px;
        margin: 0 21px;
        > h1 {
          font-size: 24px;
          white-space: pre-wrap;
        }
        > h3 {
          font-size: 15px;
          white-space: pre-wrap;
        }
      }
    }
  }
  @include tablet() {
    @include imageSet('@/assets/images/login/bg-login-ta');
    .inner_login {
      &.content {
        padding: 64px 0;
        .content_login {
          padding: 32px 20px;
          > h1 {
            font-size: 24px;
          }
        }
      }
    }
  }
  @include mobile() {
    @include imageSet('@/assets/images/login/bg-login-mo');
    .inner_login.content {
      top: 0;
      //margin-top: 64px;
      transform: translate(0);
    }
  }
}
</style>
